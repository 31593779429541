module.exports = {
  'ryan-thompson': {
    photo: 'ryan-thompson.png',
    fullName: 'Ryan Thompson',
    title: 'College Baseball Advisor',
    bio: [
      `Ryan was a three sport varsity athlete for three years at Dos Pueblos High School, in Santa Barbara, CA. Receiving All-County & All-Channel 
      League honors his junior and senior years, Ryan chose baseball over football when attending UC Davis. Earning his B.A. in Rhetoric & Communication, 
      he then enrolled at the University of San Francisco, graduating with honors while receiving his M.A. in Sports and Fitness Management.`,
      `In 2003, Ryan began his coaching and teaching careers at Cuesta College in San Luis Obispo, CA. From 2008 – 2010. Ryan was the head coach at 
      Scripps Ranch High School in San Diego, CA. In 2010, the program won its first CIF tournament game and league title in the school's history, 
      while Ryan earned league Coach of the Year accolades. Again winning Coach of the Year accolades, this time in 2011 while directing Santa Barbara 
      City College team to the college's first playoff win and super regional appearance in SBCC history. From late 2011 to 2014 he has been working in 
      Major League Baseball as the Cleveland Indian's Southern California Area Scouting Supervisor.`,
      `Ryan currently resides in San Diego, California.`,
    ],
    twitter: 'https://twitter.com/CoachThompsonSF',
    facebook:
      'https://www.facebook.com/people/Coach-Ryan-Thompson/pfbid0n7mXrGGP1hviP5wZxNiTvhiXBW1r7YdLKk2aC1VYuyZ6hBYwsc7K35SHXqAV1Xg1l',
    instagram: '',
    linkedin: '',
    logoList: [
      '485_university_of_california_davis.png',
      'cleveland-indians.png',
      'mlb.png',
    ],
    careerHighlights: [
      'Former MLB Scouting Supervisor for the Cleveland Indians in Southern California and Four Corners Territory',
      '2011 Western State Conference Championship, Coach of the Year; first Junior College player drafted in MLB 2011 Amateur Draft',
      '3 players drafted in MLB 2010 Amateur Draft, SRHS – More than any HS in California',
      'UC Davis Student Athlete Outstanding Senior Award - 2000',
      'Vice President & Recruiting Coordinator TB SoCal Baseball Organization',
      '22 years coaching baseball',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        "Arizona State University, Brown University, CAL, Cal Poly SLO, College of the Holy Cross, Columbia, Cornell University, CSU Fresno, Davidson, Dartmouth, Indiana, Kentucky, Loyola Marymount University, Northwestern University, Ohio State, Pepperdine University, Purdue University, Saint Mary's College of California, San Jose State University, Siena College, Stanford, Penn, UC Davis, UC Irvine, UC Riverside, UC Santa Barbara, UCLA, University of Arizona, University of Hawaii, University of San Diego, University of San Francisco, USC, Washington, Yale University",
      'NCAA D2':
        'Cal Poly Pomona, Colorado Mesa University, Concordia, Chico State, CSU Pueblo, Hawaii Pacific University, Regis, University of Missouri, St. Louis, University of Hawaii - Hilo, Westmont, Western Oregon University',
      'NCAA D3':
        'Adrian, Brandeis University, California Lutheran University, Claremont McKenna College, College of Wooster, George Fox, Lewis & Clark, Linfield College, New York University, Occidental College, Pomona-Pitzer, Rhodes College, Trinity University, University of La Verne, Washington College, Whittier College, Willamette University',
      NAIA: 'Hastings College, Hope International, Westmont College',
    },
    calendly: 'https://calendly.com/rthompson-2117',
  },
  'andrew-beinbrink': {
    photo: 'andrew-beinbrink.jpg',
    fullName: 'Andrew Beinbrink',
    title: 'College Baseball Advisor',
    bio: [
      `Andrew Beinbrink is the CEO and one of the co-founders of SportsForce. He was a 3 sport athlete in high school and former college 
      (Arizona State University) and professional baseball player (Tampa Bay Rays & Texas Rangers).`,
      `Andrew brings a wealth of hands-on college recruiting and sports experience. He is continuously inspired to teach student-athletes, 
      coaches, and parents how to reach their potential, play sports with integrity, and achieve their goals on and off the field. Andrew has 
      personally assisted over 300 student-athletes and families through the college recruiting process.`,
    ],
    twitter: 'https://twitter.com/beinbrink28',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      '25_arizona_state_university.png',
      'tampa-bay-rays-logo.png',
      'texas-rangers-logo.png',
    ],
    careerHighlights: [
      'MLB Amateur Draft 1995 - Drafted14th Round – Boston Red Sox',
      '1995 - Accepted Baseball Scholarship to Arizona State University',
      '4 Time All-American (NCAA DI - Arizona State)',
      'Signed MLB Amateur Draft (7th Round Tampa Rays - 1999)',
      'Six Seasons of Pro Baseball (Tampa Rays & Texas Rangers)',
      '10 Years of College Recruiting Advisory Experience',
      '300+ Players Advised in the Recruiting Process',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        'Stanford, Oregon, Oklahoma, Tulane, Florida, Harvard, Princeton, University of San Diego, San Diego State, Arizona State, UCLA, Virginia Commonwealth, Kansas, Washington State, Baylor, Columbia, Santa Clara, St. Marys, San Diego State, Navy, Air Force, Georgetown, New Mexico State',
      'NCAA D2':
        'Colorado Mesa, Point Loma Nazarene, Azusa Pacific, UC San Diego, Bellarmine, Merrimack, Slippery Rock, Mercyhurst',
      'NCAA D3':
        'Amherst, Chapman, University of Chicago, Pomona-Pitzer, Salsbury, Johns Hopkins, MIT, Trinity (TX), Redlands, Stevens Institute, Swarthmore, Clairemont McKenna, Bates, Haverford, Dickinson, Washington & Lee, Occidental, Wooster, Catholic University, Gettysburg, Franklin & Marshall, St. Marys, La Verne',
      NAIA: 'Westmont, William Jessup, Corban, Georgetown College, Grand View, Hope International, Judson, Lewis-Clark College, Providence Christian, Robert Morris, Vanguard, York',
    },
    calendly:
      'https://calendly.com/abeinbrink/college-scouting-evaluation?back=1',
  },
  'john-bava': {
    photo: 'john-bava.jpg',
    fullName: 'John Bava',
    title: 'College Baseball Advisor',
    bio: [
      `John has played, coached & worked in sports for the past 30 years. John began playing baseball competitively at an early age and continued 
      to improve as an athlete, earning All-League & All-American honors in baseball his senior year at Thomas Downey High School in Modesto, CA. 
      After his playing career was over, he began his coaching career as a private hitting instructor and working in professional baseball with the 
      River Cats organization.`,
      `He has been coaching and advising baseball players for the past 16 years, connecting many with college programs at all levels DI,II,III, and NAIA. 
      Most recently coaching at San Joaquin Delta College (JC) as an Asst Coach and Recruiting Coordinator, helping with their recruiting efforts.`,
    ],
    twitter: 'https://twitter.com/JohnJBava',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      'cccaa-logo.png',
      'San_Joaquin_Delta_College.png',
      'oakland-athletics-logo.svg',
    ],
    careerHighlights: [
      '1992 led Thomas Downey HS to a CIF sub-section championship game',
      '1993 All-League Catcher, Thomas Downey HS',
      '1993 Stanislaus County All-District team',
      '1993 Mizuno HS All-America',
      '6+ years as SportsForce Recruiting Advisor',
      'Assistant Coach/Recruiting Coordinator at San Joaquin Delta College',
      '16+ years of coaching/recruiting experience',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        "Cal Berkley, Northern Arizona, UC Davis, Sacramento State, Loyola Marymount, U of Hawaii, University of San Diego, Valpraiso, Portland State, Oregon State, U of Montana, Grand Canyon U, St. Mary's, U of Colorado, Colorado Springs",
      'NCAA D2':
        'CSU Stanislaus, Humbodt State, Chico St, Lincoln University, Concordia University - Portland',
      'NCAA D3':
        'UC Santa Cruz, University of Redlands, Menlo College, University of LaVerne, Chapman University, LaGrange (GA), Otterbein U (OH), Macalaster (MN), Linfield (OR), Occidental (CA), Pomona-Pitzer (CA), Claremont Mudd Scripps Colleges, Willamette (OR)',
      NAIA: '',
    },
    calendly: 'https://calendly.com/john-bava',
  },
  'kevin-klaess': {
    photo: 'kevin-klaess.jpg',
    fullName: 'Kevin Klaess',
    title: 'College Baseball Advisor',
    bio: [
      `I was a 2 sport 3-year letterman for both football and baseball, earning all-Conference honors in both
      sports and making the Orange Country North South all-star team in baseball. I got recruited to play both
      football and baseball, but I knew I had a better chance of playing baseball at a professional level. I
      turned down football scholarships to play baseball, continuing my education at Cypress College winning
      a state championship in 2013 while getting my A.A. in Business Administration. Cypress helped me get
      recruited to Lindsay Wilson college on a ninety percent scholarship. From there I transferred, doing my
      own recruiting, to Chapman University where they were able to help get grants up to seventy percent of
      tuition for good academic grades throughout high school and college. While finishing my collegiate
      playing career at chapman and I earned my B.A. in Business Management After that I was able to play
      Independent Professional Baseball for the next 3 years.`,
      `I started coaching youth travel ball while finishing up my last year of pro ball. We have built up an
      organization that helps train all ages from youth to college. We have had the pleasure of helping many
      players reach their dreams of playing baseball at the next level. We have sent numerous players to the
      collegiate level and even helped some get drafted. I am also a varsity high school coach with Marina in
      Huntington beach. I am also a Private Personal Training both as a baseball instruction and a Certified
      Fitness trainer through NCSF. My passion has always been to help others reach their highest level of
      potential and allow players to play at the next level. I have seen personally what this game can do and
      how it can transform players as it helps prepare them for the rest of their lives as they make it to the
      highest level they can play. It’s an honor to be a part of the process that gives players an opportunity
      they never thought they would be able to reach. Guiding families through the recruiting process as well
      as make sure their players continue to develop in a fashion that meets college recruiting requirements is
      the most satisfying way, I can give back to the game that has given me everything.`,
    ],
    twitter: 'https://twitter.com/kevinKlaess',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      'ncaa-logo.png',
      'naia-logo.png',
      '85_chapman_university.png',
      'Cypress_College.png',
      '1851_lindsey_wilson_college.png',
    ],
    careerHighlights: [
      `2007-2011 Huntington Beach High school Baseball and football, 3-year Varsity for both sports, All Sunset
      league 2nd team/ all-academic team football. Baseball All sunset league 1 st team/ all-academic team /
      Orange County North, South All-star team.`,
      `2016- Present, 8 years of Coaching experience. 2 years of high school coaching with Marina High School;
      8 years with both travel ball organizations Prevail Baseball/ Stacked Recruiting`,
      '2011-2013, Cypress College- 2013 CCCAA State Championship',
      '2013-2014, Lindsay Wilson College- NAIA- 90% scholarship',
      '2014-2015, Chapman University- B.A. in Business Management',
      '2015-2017, Independent Professional Baseball',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        'Long Beach, New Mexico State, Fresno State, UCSB, UCLA, USC, UCSD, UCI',
      'NCAA D2': 'Colorado Mesa University, Biola',
      'NCAA D3': 'Chapman',
      NAIA: 'Hope International',
      NJCAA: 'Cypress College, Orange Coast College, Golden West College',
    },
    calendly: 'https://calendly.com/kklaess',
  },
  'max-gunn': {
    photo: 'max-gunn.jpeg',
    fullName: 'Max Gunn',
    title: 'College Baseball Advisor',
    bio: [
      `Max brings 7 years of coaching and recruiting knowledge to SportsForce. He played Division 3 College Baseball at Thiel College 
      for two years before transferring back home to Ohio State. He currently coaches Varsity High School Baseball in Central Ohio. There, 
      he handles all recruiting for the High School. In the last 4 years, he has helped place 26 student athletes to play at the next level. 
      He also holds the Director of Baseball position for a summer organization in Central Ohio. In that role, he runs recruiting and assists 
      with the transition from Middle to High School Baseball, as well as, High School to College.`,
      `He currently resides in Central Ohio where he is a Special Education teacher and coaches high School baseball. He loves spending time with 
      his Wife Carly and Daughter Josie. He thoroughly enjoys building relationships and assisting in finding a forever home for student athletes. 
      Being a teacher, he gets joy out of seeing student athletes achieve their goals and enjoys being a part of that process. He is willing to go 
      the extra mile and put in whatever amount of work necessary to help achieve a players dreams and aspirations. `,
    ],
    twitter: 'https://twitter.com/CoachG32',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['466_thiel_college.png', 'ncaa-logo.png'],
    careerHighlights: [
      'Former Division 3 Baseball Player',
      'Current Varsity Baseball Coach',
      '6 years of College Recruiting',
      '26 Student Athletes placed to play at the next level',
      'Director of Baseball for Central Ohio Organization',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        'University of Cincinnati, Wagner University, Ohio State University, Akron University',
      'NCAA D2': 'Lake Erie College',
      'NCAA D3':
        'Defiance College, Adrian College, Wilmington College, Wittenberg University, Chatham University, Geneva College, Earlham College, Thiel College, Ohio Wesleyan University, Muskingum University, Anderson University, Marietta College',
      NAIA: 'Saint Xavier University, University of Northwestern Ohio',
      NJCAA: 'Bryant and Stratton College',
    },
    calendly: '/webformlp',
  },
  'andrew-olla': {
    photo: 'andrew-olla.jpeg',
    fullName: 'Andrew Olla',
    title: 'College Baseball Advisor',
    bio: [
      `Andrew brings 10 plus years of coaching, scouting, and recruiting knowledge to SportsForce. Andrew also had a brief stint coaching at the 
      University of Concordia (NCAA D3) before moving into professional scouting with the Milwaukee Brewers (MLB).`,
      `Now Andrew is the recruiting coordinator for Building Champions Baseball Academy with 300 plus college placed players and 30 plus MLB draft picks. 
      He has helped players achieve college dreams like LSU, Kansas State, Kansas, Oklahoma, and Arkansas to name a few for many kids averaging roughly 
      30 kids a year into college programs. During this time he also serves as an associate scout for the Philadelphia Phillies for the last 3 years.`,
      `Andrew was a 2-sport athlete at West Allis Central HS in Milwaukee Wisconsin (baseball/soccer). After that, Andrew got involved in coaching baseball 
      at Kewaskum High School. Coaching there for 6 years where they won 5 conference championships and placed several college athletes.`,
    ],
    twitter: 'https://twitter.com/Drew_Olla',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      'ncaa-logo.png',
      'philadelphia-phillies-logo.png',
      'mlb.png',
      '113_concordia_university_wi.png',
    ],
    careerHighlights: [
      'Former College Coach University of Concordia Wisconsin (NCAA D3)',
      '2015 MLB Scout School Graduate',
      '5 years of Pro Scouting',
      'Former High School Coach at Kewaskum (Wisconsin)',
      '7 years of College Recruiting Coordinator - Academy Level',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        'Arkansas, Oral Roberts, Texas AM Corpus Cristi, Oklahoma, LSU, Creighton, Kansas, Kansas State, Southern Illinois Edwardsville, University of St. Louis, Wichita State, Nebraska,University of Texas San Antonio, Arizona State, Murray State, Missouri State, Saint Joesp’s University, has University of Missouri, Arkansas State, Southeast Missouri State, University of Milwaukee Wisconsin, Ball State University',
      'NCAA D2':
        'University of Central Missouri, Drury University, Northwest Missouri State, University of Missouri St. Louis, Emporia State, Pittsburg State, Washburn University, Missouri Southern, Truman State,',
      'NCAA D3':
        'Wisconsin Whitewater, University of Wisconsin Oshkosh, University of Concordia, Hendrix College, University of Washington of St. Louis. University of Wisonsin Steven Point, Carroll College, University of Wisonsin Lutheran, Grinnell College',
      NAIA: 'Benedictine College, Central Methodist, University of Concordia Nebraska, Park University',
      NJCAA:
        'Johnson County CC, Barton County CC, Neosho County CC, San Joaquin Delta College, Cowley County CC, Madison CC, Kansas City CC, Maple Woods CC, St. Louis CC',
    },
    calendly: 'https://calendly.com/andrewolla',
  },
  'auburn-donaldson': {
    photo: 'auburn-donaldson.png',
    fullName: 'Auburn Donaldson',
    title: 'College Baseball Advisor',
    bio: [
      `I grew up playing all sports. I played baseball at Baker High School. We were Alabama 6A State Champions in 2005 & 2006. I played 2 years of Junior 
      College at College of Marin in California. My Junior Year at Southeastern University in Lakeland, FL led to being drafted by the Baltimore Orioles in 2010. 
      I am grateful & fortunate to have had the opportunity to compete with the best in the game! I graduated from the University of South Alabama earning 
      a Bachelor of Science in Business Administration.`,
      `During 2016-2020, I assisted my brother and my family through the recruiting process. Myles White competed at Dothan-Wallace Community College for 2 years. 
      Currently, he is competing at Division 1, Jackson State University. I coached one season at Dothan-Wallace CC under Mackey Sasser. We finished 2nd in the 
      State. I spend the summers coaching in Collegiate Leagues.`,
      `Navigating the recruiting process can be a daunting task. Establishing and building key relationships within communities is our focus. We want to provide 
      the best resources available for student-athletes and families. My decision to join a team of like-minded individuals who share the same passion was simple.`,
    ],
    twitter: 'https://twitter.com/j_auburn88',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      'College_of_Marin.png',
      '1801_southeastern_university.png',
      'baltimore-orioles-logo.png',
      'Wallace_Community_College.png',
      'naia-logo.png',
    ],
    careerHighlights: [],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        'Jackson State University, University of Florida, Troy University, University of South Alabama, Marshall University, Georgia Southern University, Jacksonville State University, Bethune-Cookman University',
      'NCAA D2':
        'University of Alabama at Huntsville, Columbus State University',
      'NCAA D3': 'Huntingdon College',
      NAIA: 'Tennessee Wesleyan University, University of Mobile',
      NJCAA:
        'Bishop State Community College Southern Union Community College, Andrew College , Dothan-Wallace Community College, Wallace Selma Community College',
    },
    calendly:
      'https://calendly.com/sports_advising_fundraising/evaluation-call',
  },
  'braden-persian': {
    photo: 'braden-persian-v2.jpeg',
    fullName: 'Braden Persian',
    title: 'College Baseball Advisor',
    bio: [
      `Braden is currently a Head Coach in the Power Summer Collegiate League and the Mainland British Columbia Director of Scouting for Prep Baseball Report. 
      Braden also works as a coach with the Abbotsford Cardinals in the British Columbia Premier Baseball League.`,
      `Braden's extensive coaching experience includes the University of Calgary Dinos, Douglas College Royals, Calgary Bucks, White Rock Tritons, Top 40 
      Provincial teams in Alberta and BC and Big League Experience.`,
      `Braden is driven by watching his players develop, grow and achieve their baseball dreams. He is passionate about sharing his knowledge of baseball and 
      enjoys nothing more than being on the diamond.`,
      `Braden currently resides in White Rock, British Columbia with his wife Madi and his dog Dirk.`,
    ],
    twitter: 'https://twitter.com/BradenPersian',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      'nwac-logo.png',
      'pbr-logo.jpeg',
      'calgary-dinos-logo.png',
      'douglas-college-royals.jpeg',
    ],
    careerHighlights: [],
    myPlayerCollegesByDivision: {},
    calendly: 'http://calendly.com/bradenpersian',
  },
  'chris-gonzales': {
    photo: 'chris-gonzales.png',
    fullName: 'Chris Gonzales',
    title: 'College Baseball Advisor',
    bio: [
      `Chris was a three-sport athlete at Coconino High School in Flagstaff, Arizona and lettered in Football, Basketball and Baseball. During his senior year 
      of baseball, he suffered a tough ankle injury that required surgery. After graduating high school and a year of rehabbing his ankle he chose to return to 
      baseball.`,
      `He attended Sterling College in Sterling, Kansas and enjoyed playing baseball for a couple more years. In 1999 he graduated from Sterling College with a 
      Bachelor of Science degree in Biology. In 1999 he married his wife, Dawn and currently has 3 kids. He coached his 3 kids from youth level baseball up to 
      the high school level. Several players he coached went on to play college and professional baseball. Hispassion in coaching was to help players get to the 
      next level and get their education. During that time, he saw the challenges of getting players connected with coaches and colleges that were the right fit 
      for the player.`,
      `In 2015 Chris attended the Pro Umpire Camp in Houston Texas. He currently works games for high school level showcases, summer collegiate leagues, Arizona 
      Interscholastic Association, Arizona Community College Athletic Conference, and invites to Spring Training by the Dodgers, White Sox, Diamondback, Padres, 
      Cubs and Angels. His umpire experience gives him a unique opportunity to be on the field with players to observe their skills and interest for the game.`,
      `Chris currently resides in Surprise, Arizona. He is excited to be a part of the SportsForce team to help players find the right college fit to continue to 
      playing baseball at the next level.`,
    ],
    twitter: 'https://twitter.com/ChrisGonzalesSF',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['naia-logo.png', '1817_sterling_college.png'],
    careerHighlights: [],
    myPlayerCollegesByDivision: {},
    calendly: 'https://calendly.com/coachgonzales15',
  },
  'cliff-brown': {
    photo: 'cliff-brown-v2.jpg',
    fullName: 'Cliff Brown',
    title: 'College Baseball Advisor',
    bio: [
      `Coach Cliff brings a wealth of experience to Sports Force.  Cliff joined the coaching staff at Lindenwood University as the Director of Player Development 
      in August of 2022 after serving as an Assistant Coach at the University of Missouri- St. Louis during the 2022 season.  Prior to that, he served as the Head 
      Coach at Harper College for 10 years. During his tenure at Harper, Cliff oversaw the development of 31 All-Conference Players, 27 All-Region selections, and 
      3 NJCAA All-Americans.`,
      `In total, Cliff has 29 years of professional and amateur coaching experience and spent 23 years coaching at the college level.  He has coached at the 
      Division I, Division II, and Junior College level and has coached 40 players that have gone on to play professional baseball.`,
      `Cliff spent 3 years working in the Chicago White Sox organization.  He spent one season as a Strength and Conditioning Coach for the White Sox Rookie Ball 
      team. He also oversaw all operations of the White Sox Training Academy in Lagrange, IL where he worked with players of all ages in hitting, pitching, and 
      catching.`,
    ],
    twitter: 'https://twitter.com/cwbrown38',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      '243_lindenwood_university.png',
      '1034_university_of_missouri_st_louis.png',
      '2193_william_rainey_harper_college.png',
      'ncaa-logo.png',
      'chicago-white-sox-logo.png',
    ],
    careerHighlights: [],
    myPlayerCollegesByDivision: {},
    calendly: 'https://calendly.com/coachcliffbrown38/60min?',
  },
  'john-cackowski': {
    photo: 'john-cackowski.png',
    fullName: 'John Cackowski',
    title: 'College Baseball Advisor',
    bio: [
      `John has been part of the Colorado amateur baseball landscape for 30 years. He graduated Highlands Ranch HS in 1992. John went on to play 2 years of 
      college baseball at Colorado Northwestern, and from there went to Colorado State University and received a bachelor degree in History. After graduating 
      college, John started his professional career at ThunderRidge HS in the fall of 1997. He was a varsity baseball assistant from 1997 to 2006, helping the 
      program win its first baseball state championship in 2004. John was also the head softball coach from 2002 to 2006 earning Coach of the Year honors in 2002 
      after winning the Continental League championship for the first time in program history. He would finish with a career winning percentage of 640%, making 
      the state playoffs every year including the Final 8 and Final 16.`,
      `In 2006, John had an opportunity to become the head baseball coach at his alma mater -Highlands Ranch. In his first-year coaching, John won Continental 
      League Coach of the Year honors after winning the Continental League. Highlands Ranch would make the playoffs the next 6 years, something that they had 
      not accomplished in the previous 5 years before John arrived. In 2011 and 2012, Highlands Ranch would make back-to-back 5A Final Four appearances for the 
      first time in program history, while reaching a national ranking of 18 by Baseball American in 2012. John would finish with a .610 winning percentage at 
      Highlands Ranch.`,
      `John was also coach and scout for USA baseball, and a professional scout for 8 years for multiple organizations.`,
      `John currently resides in Highlands Ranch, Colorado, and is married to his wife, Natalie. He has a daughter who is a sophomore at Colorado State, and a 
      son who is a junior at Arapahoe High School. John is currently coaching baseball at Arapahoe High School.`,
    ],
    twitter: 'https://twitter.com/cack92',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['njcaa-logo.png', 'usa-baseball-logo.png', 'pbr-logo.jpeg'],
    careerHighlights: [
      '30 + years of coaching amateur baseball in Colorado',
      'Head varsity baseball and softball coach combined for 15 years',
      'Coached and scouted for USA Baseball from 2008 to 2014',
      'Professional baseball scout for 9 years with multiple organizations',
      'Coached 9 players who would go on to get drafted',
      '57 previous players went on to play at the college level',
    ],
    myPlayerCollegesByDivision: {},
    calendly: '/webformlp',
  },
  'kyle-skinner': {
    photo: 'kyle-skinner-v2.jpg',
    fullName: 'Kyle Skinner',
    title: 'College Baseball Advisor',
    bio: [
      `Kyle Skinner is more than an educator and coach—he's a guiding force in the journey of aspiring student-athletes. With a robust academic background and 
      extensive coaching experience, Kyle brings a wealth of knowledge and insight to the world of college baseball recruiting. Kyle's academic journey began 
      at West Los Angeles College, where he earned an Associate of Science in Physical Education in 2009. Building on this foundation, he obtained a Bachelor's 
      degree in Exercise Science & Sports Management from Valley City State University in 2012. Kyle then pursued his passion for teaching, earning a Master's 
      in Teaching with a specialization in Health & Fitness from Northwest University in 2015.`,
      `Transitioning seamlessly into a professional role, Kyle has dedicated himself to shaping young minds as a Physical Education & Health Teacher since 2015. 
      However, it's his extensive coaching background that truly sets him apart. As the Head Varsity Baseball Coach at Interlake High School in Bellevue, WA, 
      from 2015 to 2023, Kyle honed his skills in player development and mentorship. Kyle's coaching tenure extends beyond the high school level. He has held 
      pivotal roles with prestigious teams such as the Boys of Summer Raptors 18u College Showcase team and the St. Louis Cardinals Area Code Scout Team, where 
      he served as Field Manager and Assistant Coach during an MLB Scouting Externship in 2012. Beyond coaching, Kyle has been instrumental in the success of 
      numerous players in their college baseball endeavors. His leadership positions at Inglemoor and Bellevue High Schools, along with his involvement with 
      the Sammamish Baseball Academy and Let’s Play: Professional Baseball Instruction, underscore his commitment to fostering talent at every level.`,
      `Kyle's dedication to continuous improvement is evident in his pursuit of prestigious certifications, including those from the National Strength & Conditioning 
      Association and the United States Sports Academy. Furthermore, his own collegiate baseball career was distinguished by numerous awards and scholarships, 
      reflecting his prowess both on and off the field.`,
      `Kyle's impact transcends individual achievements; it's reflected in the success stories of the players he has mentored. Kyle's players have found success 
      at various levels of collegiate baseball. In summary, coach Skinner isn't just a coach or a teacher—he's a mentor, a guide, and a dedicated advocate for 
      student-athletes. His journey from celebrated student-athlete to respected educator and coach speaks volumes about his commitment to fostering talent and 
      promoting healthy, active lifestyles. If you're looking to navigate the complex landscape of college baseball recruiting, Kyle Skinner is your trusted 
      advisor every step of the way.`,
    ],
    twitter: 'https://twitter.com/CoachSkinner11',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      '1856_valley_city_state_university.png',
      'northwest_university.png',
      'naia-logo.png',
    ],
    careerHighlights: [
      'Northwest University, 2015 - Masters in Teaching: Health & Fitness',
      'Head Varsity Baseball Coach, 2015-2023 - Interlake High School - Bellevue, WA',
      'Field Manager/Assistant Coach, 2012 - St. Louis Cardinals Area Code Scout Team - MLB Scouting Externship',
      'Director of Strength & Conditioning - Sammamish Baseball Academy',
      'Certified Sports Psychology Coach - National Exercise & Sports Trainers Association (NESTA)',
      'American Baseball Coaches Association',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        'Saint Peters University, Campbell University, Marshall University, Seattle University, University of Northern Colorado, University of San Fransisco, University of Washington, Washington State University, William & Mary',
      'NCAA D2': 'Cal State San Bernardino',
      'NCAA D3':
        'Chapman University, Claremont McKenna College, Linfield University, Luther College, Occidental College, Pacific Lutheran University, Pacific University, University of Puget Sound',
      NAIA: 'Eastern Oregon University, University of Jamestown',
      NJCAA:
        'San Joaquin Delta, Bellevue College, Blue Mountain Community College, Columbia Basin College, Edmonds College, Green River College, Skagit Valley College, Umpqua Community College, Williston State College, Yuba College',
    },
    calendly: 'https://calendly.com/coachskinner11',
  },
  'lou-proietti': {
    photo: 'lou-proietti-v2.jpg',
    fullName: 'Lou Proietti',
    title: 'College Baseball Advisor',
    bio: [
      `Lou is a native of Hamilton, Ontario native and graduated from the University of Massachusetts, Amherst in 2007 and received his master's degree from 
      the university in 2009. Proietti also earned a Master's in Organizational Leadership from Rider in 2015. At UMass Amherst, he played shortstop while 
      leading the Minutemen in batting average and stolen bases in 2006. Prior to UMass, Proietti played the 2004 season at Saint Peter's before transferring 
      to Potomac State College in Keyser, West Virginia for the 2005 season. He helped Potomac State advance to the Junior College World Series, where the team 
      finished 5th nationally.`,
      `His coaching career includes three years as the Head Baseball Coach at Saint Peter’s University as well as stops at Stonehill College, Rider University and 
      Georgetown Preparatory School.`,
      `As Head Coach at Saint Peter’s during a condensed 2021, Proietti guided the Peacocks to the program's best MAAC output since the 2016 campaign. In 2020, 
      before the COVID shutdown, Proietti helped lead his team to his first collegiate win as a Head Coach on the road against Rutgers. It was the first win in 
      program history against a Power 5 opponent. Six student-athletes were named to the MAAC All-Academic team in June and 13 to the MAAC Academic Honor Roll in 
      July.`,
      `Before Saint Peter’s, Proietti spent the previous four seasons at Stonehill College serving as the team's recruiting coordinator, hitting coach, and working 
      closely with both the infielders and outfielders.`,
      `Prior to Stonehill, Proietti was an assistant at Rider from 2012-15. During his tenure, he was the hitting coach and third base coach, helping lead the 
      Broncs to a pair of regular season MAAC Championships, including a season that broke the school record for most wins in a regular season in 2013. Head coach 
      Barry Davis also won the MAAC Coach of the Year award in both 2013 and 2015 with Proietti on the staff.`,
      `Before his time at Rider, Proietti spent the 2009-10 season at McMaster University in Hamilton, Ontario, then acted as hitting coach for the Oneonta Outlaws 
      of the PGCBL (Perfect Game Collegiate Baseball League) from 2010-12, and from 2013-2016, he coached the Plymouth Pilgrims in the NECBL (New England Collegiate 
      Baseball League) as hitting coach. He helped 1st round MLB draft pick, Brent Rooker from Mississippi State win the 2015 NECBL MVP award and also coached 
      World Series MVP Jeremy Pena that summer.`,
      `I truly enjoy working with young men who love the game of baseball and want to continue to compete at the next level. Having experienced college baseball 
      as both a player and coach, I understand the type of experience, education and relationships competing at the college level provides. My goal is to help 
      players and families understand the recruiting process and how to create the best possible opportunities through a strategic system.`,
    ],
    twitter: 'https://twitter.com/LouProietti',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      'ncaa-logo.png',
      '509_university_of_massachusetts_amherst.png',
      '1636_st_peters_college.png',
      '486121_potpmac_state_college_of_west_virginia_university.png',
      '433_stonehill_college.png',
      '912_rider_university.png',
    ],
    careerHighlights: [],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        'Mississippi State, University of Maine, Rider University, University of Buffalo, Virginia Tech',
    },
    calendly: 'https://calendly.com/coachlouproietti',
  },
  'matt-sugarman': {
    photo: 'matt-sugarman.jpg',
    fullName: 'Matt Sugarman',
    title: 'College Baseball Advisor',
    bio: [],
    twitter: 'https://twitter.com/CoachSug',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['ncaa-logo.png', 'pbr-logo.jpeg'],
    careerHighlights: [],
    myPlayerCollegesByDivision: {},
    calendly: 'https://calendly.com/coachsugarmanaz',
  },
  'nick-kort': {
    photo: 'nick-kort-v2.jpg',
    fullName: 'Nick Kort',
    title: 'College Baseball Advisor',
    bio: [
      `Nick Kort, born and raised in Chandler, Arizona, attended powerhouse Hamilton High School. 
      He played club ball under the direction of former Big League catcher Kelly Stinnett and Atlanta Braves pitcher 
      Mike Remlinger. Following his graduation in 2017, Nick signed to play college baseball at Benedictine-Mesa, 
      a top-ranked NAIA program and reunited with his club coach Kelly Stinnett. Over the next four years, he excelled 
      as a relief pitcher while earning a bachelor's degree in Sports Management. Notably, he contributed to 
      Benedictine's first-ever postseason appearance in the NAIA National tournament in 2021.`,
      `Post-baseball, Nick pursued further education, earning a Master's in Business Administration at Benedictine 
      University. Concurrently, he embarked on a coaching career at the Dbacks academy, where he coached the high school 
      RBI program. Under his guidance, the team achieved a regional championship in 2023. Since then, Nick has been 
      instrumental in helping student-athletes navigate the recruitment process and optimize scholarship opportunities. 
      His multifaceted journey reflects a deep commitment to both the sport of baseball and the development of young 
      talent.`,
    ],
    twitter: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['naia-logo.png'],
    careerHighlights: [
      `Pitched 4+ years at the NAIA level`,
      `7+ years of high school scouting`,
      `Assisted dozens of high-school athletes to achieve a college roster spot`,
    ],
    myPlayerCollegesByDivision: {},
    calendly: 'https://calendly.com/nkort',
  },
  'nino-giarratano': {
    photo: 'nino-giarratano-v2.jpeg',
    fullName: 'Nino Giarratano',
    title: 'College Baseball Advisor',
    bio: [],
    twitter: '',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['ncaa-logo.png', 'njcaa-logo.png', 'usa-baseball-logo.png'],
    careerHighlights: [],
    myPlayerCollegesByDivision: {},
    calendly: '/webformlp',
  },
  'ryan-schwertman': {
    photo: 'ryan-schwertman.png',
    fullName: 'Ryan Schwertman',
    title: 'College Baseball Advisor',
    bio: [],
    twitter: 'twitter.com/rdschwertman',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['ncaa-logo.png', 'cincinnati-reds-logo.png', 'mlb.png'],
    careerHighlights: [],
    myPlayerCollegesByDivision: {},
    calendly: '/webformlp',
  },
  'scott-hormann': {
    photo: 'scott-hormann-v2.jpg',
    fullName: 'Scott Hormann',
    title: 'College Baseball Advisor',
    bio: [
      `Scott was three sport athlete at Fairfield High School in Fairfield OH. He Then played QB at Eastern Michigan University. After college Scott moved 
      to Denver, Colorado where in 1999 he got the bug to coach. He started coaching a youth team and quickly became addicted to coaching and helping kids. 
      In 2004 he coached his first high school and has now been a high school coach for 19 years. He is the head baseball coach at Lutheran High School taking 
      his team to the final four the first two years he has been there.`,
      `He also owns USA Prime Colorado and coaches the USA Prime American 17U team in the summer. USA Prime is a national organization that plays in the best 
      summer tournament and in front of some of the best colleges in the country.`,
      `Scott lives in Denver Colorado with His wife Rebecca they have 5 children ages 29 to 19.`,
    ],
    twitter: 'https://twitter.com/coachscott11',
    facebook: 'https://www.facebook.com/scott.hormann',
    instagram: '',
    linkedin: '',
    logoList: ['729_eastern_michigan_university.png'],
    careerHighlights: [],
    myPlayerCollegesByDivision: {},
    calendly: '/webformlp',
  },
  'zach-nadolny': {
    photo: 'zach-nadolny.jpeg',
    fullName: 'Zach Nadolny',
    title: 'College Baseball Advisor',
    bio: [],
    twitter: 'twitter.com/znadolny28',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['ncaa-logo.png'],
    careerHighlights: [],
    myPlayerCollegesByDivision: {},
    calendly: '/webformlp',
  },
  'fred-defrance': {
    photo: 'fred-defrance.jpeg',
    fullName: 'Fred DeFrance',
    title: 'College Baseball Advisor',
    bio: [
      `My deep passion for baseball has always included helping young student-athletes and guiding them on their 
      own paths to live out their dream of playing college baseball. Having been a college coach for over 6 years while 
      serving as a recruiting coordinator, it was my pleasure to see young lives change when they received their commitment 
      from our coaching staff and baseball program. I was fortunate enough to live out my own dream of becoming a professional 
      player, but unfortunately injuries are part of the game and that is what ended my playing career. I immediately found a 
      career in coaching to stay involved with baseball, which I never thought I would be able to do after my playing days ended. I now look forward to helping as many 
      student-athletes as I can live out their own dreams!`,
    ],
    twitter: 'https://x.com/freddyd8s',
    facebook: '',
    instagram: 'https://www.instagram.com/freddyd8',
    linkedin: '',
    logoList: [
      'ncaa-logo.png',
      '510_University_of_Massachusetts,_Boston.png',
      '358_ramapo_college.png',
      '152_fairleigh_dickinson_university_florham.png',
    ],
    careerHighlights: [
      'NCAA College Coach for 6+ years',
      'Recruiting Coordinator - NCAA DIII - UMASS Boston',
      'Assistant Coach - NCAA DIII - Ramapo',
      'Assistant Coach - NCAA DIII - Fairleigh Dickinson University at Madison',
      '2007 NJCAA All-American County College of Morris',
      '2008 17th Round Prospect of the Kansas City Royals',
      '2012 Free agent prospect of the Boston Red Sox',
      '16+ Years Coaching Experience (High School & Club Baseball',
      'Coached 100+ players that went on to play in college',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        'University of Florida, Coastal Carolina, Marshall University, Northeastern University, Rutgers University, Long Island University, UNC Wilmington, UNC Greensboro, Brown University, Lafayette College, University of Maine',
      'NCAA D2': 'Tusculum University, Tufts University',
      'NCAA D3':
        'Wesleyan University - CT, UMass Boston, Ramapo College, Shenandoah University, Brandeis University',
    },
    calendly: '/webformlp',
  },
  'caleb-cox': {
    photo: 'caleb-cox.png',
    fullName: 'Caleb Cox',
    title: 'College Baseball Advisor',
    bio: [
      `Caleb Cox was named the eighth, and youngest, head coach in Garden City Community College Baseball history in June 2022.  
      He came to Garden City after a one-year stint as an assistant at Regis University in Colorado.  There, he coached pitchers 
      and catchers and oversaw recruiting operations. That season, the Rangers took second in the Rocky Mountain Athletic 
      Conference, their highest finish in school history. Cox also helped mold First-Team All-Conference pitcher, Justin 
      Kleinsorge, who led the conference with 103 punchouts.`,
      `Before Regis, Cox was a graduate assistant at Colorado State-Pueblo. Prior to that, he spent the 2020 season as the 
      catcher's coach at Butler Community College, helping guide the Grizzlies to a 15-4 mark before the season was canceled 
      due to COVID.`,
      `Cox began his collegiate playing career as a catcher at Butler Community College. He then signed with Arkansas 
      Little-Rock before transferring to Pittsburg State to finish his career.`,
      `Cox grew up in Broomfield, Colorado and is the son of former big leaguer Darron Cox, and other brother of Jonah Cox; 
      who led Oral Roberts University to the Men's College World Series in 2023 with his 47 game hit streak. He earned his 
      bachelor's degree from Pittsburg State University, and currently resides in Colorado Springs, Colorado with his wife 
      Brittany and son Beckham.`,
      `"The greatest joy in my baseball career is the moment my players have told me their plans for commitment at the collegiate level.  To see a young man's progression from an initial conversation about their dream of playing college baseball- walking with them through the training and recruiting process; then, come to fruition on signing day are moments I will always remember and cherish." `,
    ],
    twitter: 'https://twitter.com/calebcox303',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      'njcaa-logo.png',
      'ncaa-logo.png',
      '362_regis_university_co.png',
      '699_colorado_state_university_pueblo.png',
      '997_university_of_arkansas_little_rock.png',
      '904_pittsburg_state_university.png',
    ],
    careerHighlights: [
      `Catcher at Butler Community College, University of Arkansas- Little Rock and Pittsburg State University.`,
      `2017 NBC All-American`,
      `Catching Coordinator- Butler Community College`,
      `Graduate Assistant Coach/Operations Coordinator- Colorado State University- Pueblo`,
      `Assistant Coach/Recruiting Coordinator- Regis University`,
      `Head Baseball Coach- Garden City Community College`,
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        'Central Arkansas University, University of Arkansas- Little Rock, Southeastern Missouri University, Gardner-Webb University, University of San Francisco, University of Northern Colorado, South Dakota State University, Wichita State University, University of Kansas, Grambling State University, Creighton University.',
      'NCAA D2': `University of Central Oklahoma, Northern State University, Newman University, Emporia State University, Northwestern Oklahoma State University, Southwestern Oklahoma State University, Pittsburg State University, Missouri Western State University, Northwest Missouri State University, William Jewell University, Holy Family University, St. Edward's University.`,
      'NCAA D3': 'Curry College',
      NAIA: 'LSU-Alexandria, Clarke University, Park University, Belleview University',
      NJCAA: '',
    },
    calendly: 'https://calendly.com/coach-caleb-cox',
  },
  'ryan-shawley': {
    photo: 'ryan_shawley.jpeg',
    fullName: 'Ryan Shawley',
    title: 'College Baseball Advisor',
    bio: [
      `Ryan was a two-sport athlete at Greater Latrobe High School in Latrobe, PA. While at Latrobe Ryan was a part of the 
      school’s best team in school history winning the WPIAL 5A Championship as well as the State 5A Championship He received 
      All-Section Honors as well as being named to the Terrific Ten his Senior Year. He currently still holds the school record for season 
      ERA with a 0.56. After High school Ryan went onto play at Chatham University where he had a decorated playing career. 
      He played across the infield and was in the starting rotation. He ranks in the top-5 in program history in innings pitched, 
      strikeouts and wins and holds the program record for games started and games played.`,
      `Ryan began his coaching career immediately after graduating from Chatham. He started as a Graduate Assistant at Keiser University, a nationally 
      ranked NAIA school. That summer he had the opportunity to coach in the Northwoods League with the Wausau Woodchucks. That next fall he had the chance 
      to join the Division 1 coaching ranks with Gardner-Webb University in Boiling Springs, NC. He served as the Head Developmental Coach there and recruited 
      and signed a class of 13 athletes. His most recent stop came at Mid Major Powerhouse Kent State University in Kent, OH. There he was the Director of 
      Player Development and oversaw all of the data and technology.`,
      `Ryan currently resides with his Fiancé Katie and their dog Blue in Pittsburgh, PA.`,
      `“Taking student-athletes' game to the highest level is something I am extremely passionate about. Baseball and college have given me a unique 
      perspective on what young men can achieve, and I want to see every player I coach, advise, and mentor succeed on and off the field to the best of 
      their ability.”`,
    ],
    twitter: 'https://x.com/ShawleyRyan',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      'ncaa-logo.png',
      '756_gardner_webb_university.png',
      '794_kent_state_university.png',
      '686_chatham_university.png',
    ],
    careerHighlights: [
      'NCAA DI - Director of Player Development - Kent State',
      'NCAA DI - Development Coach - Gardner-Webb University',
      'Coached 10 players who have gone onto to play professional baseball.',
      'Never had a losing season as a coach.',
      'Finalist for Male Athlete of the Year at Chatham University.',
    ],
    myPlayerCollegesByDivision: {},
    calendly: 'https://calendly.com/coachshawley',
  },
  'lloyd-gage': {
    photo: 'lloyd_gage.jpg',
    fullName: 'Lloyd Gage',
    title: 'College Baseball Advisor',
    bio: [
      `The last 24 years have been more than I could have ever asked for. Been to the yard every day but never
      felt like I was at ‘work’. Coaching was more than a career, it was a family affair! It brought smiles,
      cheers, laughter, and joy. It brought devastation, failure, and tears. Most importantly it brought
      relationships, lifelong friendships, and experiences that can never be replaced. I will cherish everything
      these last 24 years have brought me and my family. I am beyond grateful to every single player, coach,
      staff member, administrator, and parent who trusted in our process along the way.`,
      `I've spent my entire life in baseball and am grateful for the opportunities it has given me. The lifelong
      relationships built on and off the field are invaluable. I'm passionate about the game and the lasting
      impact coaches have on players. With 25 years of experience coaching high school and college athletes, I
      have a solid foundation for this role. I'm excited for this opportunity to enhance the journey for players
      and their families. I've been married to my wife Lori since 2015, and we have four children.`,
      `The journey will continue in the game, just in a different position of leadership to impact players and
      families lives. I’ve taken on a College Recruiting Advisor role with @SportsForceBB and am excited to get
      an opportunity to work with a select number of players and their families on their journey to find the
      perfect college fit. College baseball is a goal for many! Although it’s competitive, with the right game
      plan and guidance it can be an enjoyable experience. One that I’m eager to help players and families
      accomplish. If you have any questions I’ll do my very best to help. Just shoot me a DM or email me.`,
    ],
    twitter: 'https://twitter.com/coachgage48',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      'ncaa-logo.png',
      'njcaa-logo.png',
      '682_central_washington_university.png',
      '723_east_central_university.png',
      '485425_Murray_State_College.png',
    ],
    careerHighlights: [
      '22 Years Coaching at the Collegiate Level (NJCAA/NCAA/NAIA) 600+ Wins',
      '90% Player Retention and Graduation Rate as a Collegiate Coach',
      '2013 NJCAA D2 National Champion with Murray State College',
      'Inducted in to the Murray State College Athletics Hall of Fame',
      'Coached/Mentored 12 All-Americans; 35 Players who have gone on to play Professionally',
      'B.S. Health Education & M.S. in Health, Human Performance and Recreation',
      '4 Years Coaching in the WCL (2 Pitchers of the Year/10 WCL All-Stars)',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1': `Oklahoma University, University of Kentucky, Mississippi State University, Texas Tech University, Oklahoma State University, Oregon State University, Washington State University, Oral Roberts University, Dayton University, Penn State University, Louisiana Lafayette, Lamar, Tarleton State, Abilene Christian University, Stephen F Austin, University of Arkansas, University of South Florida, Ohio State University, Indiana University, South Alabama, Arkansas State University, Southeast Missouri State University`,
      'NCAA D2': `Barry University, University of Central Oklahoma, East Central University, Southeastern Oklahoma State, Southwestern Oklahoma State, Southern Arkansas University, Arkansas Monticello, Henderson State University, Central Washington University, Northwest Nazarene University, Saginaw Valley State, Fort Hays State`,
      'NCAA D3': 'Puget Sound University, UT Dallas',
      NAIA: 'Lewis Clark State College, Friends University, Southwestern (KS)',
    },
    calendly: 'https://calendly.com/coachgage33',
  },
  'robert-dudley': {
    photo: 'robert_dudley.jpg',
    fullName: 'Robert Dudley',
    title: 'College Baseball Advisor',
    bio: [
      `Robert was an All-Conference baseball player and four year starter at South Stanly High School in Norwood, NC, where he also lettered in three other sports. 
      He went on to be an All-Region performer while playing club baseball at North Carolina State University while earning his degree in History.`,
      `He currently serves as the Hitting Coach at Catawba Valley Community College in Hickory, NC. Before CVCC, he served as Head Coach at Wake Technical 
      Community College for five seasons, as well as stints at Heritage High School and with the NC State club baseball team.`,
      `During his time at the high school and junior college levels Robert has familiarized himself with the recruitment and placement process and developed 
      a passion for the education, development and advancement of student-athletes.`,
      `Robert resides in Hickory, NC with his wife Meg, their three sons Ted, Jack, and Walter, and their dog Frank.`,
    ],
    twitter: 'https://x.com/CoachDudley13',
    facebook: 'https://www.facebook.com/robert.dudley.9',
    instagram: 'https://www.instagram.com/robertdudley13',
    linkedin: '',
    logoList: [
      'ncaa-logo.png',
      'njcaa-logo.png',
      '1821_catawba_valley_community_college.png',
      '485214_wake_technical_community_college.png',
    ],
    careerHighlights: [
      'NJCAA Head Coach',
      'Head Coach - Wake Tech',
      'Helped over 35 student-athletes move on the four year level from junior college',
      'Helped the program set over 20 team and individual records while at Wake Tech CC',
      'Won several medals while coaching teams at USA Baseball NTIS events',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        'Rice, UNC-A. Campbell, Seton Hall, Xavier, Iowa, Charleston Southern',
      'NCAA D2':
        'Coker, UVA-Wise, Mars Hill, Emory & Henry, Erskine, Limestone, University of Charleston, Augusta',
      'NCAA D3': 'Pfeiffer, William Peace, Oglethorpe, North Central ',
      NAIA: 'Bluefield, Doane, Keiser, Union, St. Andrews',
    },
    calendly: '/webformlp',
  },
  'cass-hargis': {
    photo: 'cass_hargis.jpg',
    fullName: 'Cass Hargis',
    title: 'College Baseball Advisor',
    bio: [
      `Cass joins SportsForce with 5 years experience as a high school head coach and 2 years experience as a division 1 assistant coach.`,
      `Hargis spent the 2023 and 2024 season as the head coach at St. Thomas More in Lafayette, LA. His team's reached the Division 2 semi-finals in both years.`,
      `Prior to his time at St. Thomas More, he was an assistant at McNeese St. University, where he was the offensive coordinator and outfield coach, as well as camp coordinator.`,
      `Hargis was the head coach at his alma mater, Holy Cross School in New Orleans, from 2015-2018. As the head coach of the Tigers, he averaged 21 wins a season, 
      made the State Semi-Finals twice, in 2017 and 2018, and was American Legion State Runner-up in 2016.`,
      `In 2017, he was inducted into the Southeastern Louisiana University Athletic Hall of Fame where he played centerfield for the Lions. Hargis earned his degree and played four 
      years of baseball at SE Louisiana from 2008-2011.`,
      `In those years, he was named a 2008 Louisville Slugger/PING Baseball Freshman All-American, was a two-time member of the All-Conference Teams (2008, 2009), and is Southeastern 
      La.’s all-time leader in hits, runs, and triples, and is second all-time in doubles.`,
      `After graduating, Hargis became an Assistant Coach at Southeastern La. in 2013 helping lead them to a 35-win season and a birth to the Southland Conference Championship Game.`,
      `In 2014, Hargis went back to St. Thomas Aquinas as the Assistant Baseball/Football coach in Hammond, LA where after leaving in 2012.`,
      `Hargis currently resides in Youngsville, LA with his wife, Matte, and their newborn son, Cooper.`,
    ],
    twitter: 'https://x.com/cass_hargis',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      'ncaa-logo.png',
      '939_southeastern_louisiana_university.png',
      '836_mcneese_state_university.png',
    ],
    careerHighlights: [
      'NCAA DI - Asst. Coach - Southeastern Louisiana',
      '2 years as assistant coach in D1 baseball (McNeese State University)',
      '5 years as head high school head coach',
      'Inducted into Southeastern Louisiana University Athletic Hall of Fame',
      'SLU career leader in hits, triples, runs scored, games started and games played',
    ],
    myPlayerCollegesByDivision: {},
    calendly: 'https://calendly.com/coachhargissf',
  },
  'austin-swift': {
    photo: 'austin_swift.jpg', // URL from CSV
    fullName: 'Austin Swift',
    title: 'College Baseball Advisor', // Assuming the title based on context
    bio: [
      `Austin hails from northwest Ohio and graduated from Kalida High School in the summer of 2016. Throughout high school, he was a 2-sport athlete in both 
      baseball and soccer. Following high school, Austin received a baseball scholarship to go on and play at Indiana Wesleyan University in Marion, IN. 
      As a left-handed pitcher at IWU, Austin appeared in 44 games and made 30 starts on the mound. He finished his career with a 4.13 ERA and 138 strike outs. 
      In his last two seasons, he amassed a W/L record of 9-1. Austin graduated from IWU in the Spring of 2021 earning his B.A. in Business Administration & 
      Sports Management.`,
      `After IWU, Austin went on to coach at Ottawa University in Ottawa, KS from 2021-2024. In the fall of 2023, he was named OU's Pitching Coach and Recruiting 
      Coordinator. In his stint with the Braves, Austin helped the program reach its school record in wins in 2022 and 3rd most wins in 2023. While there, 
      he received his M.B.A. with a concentration in leadership development. He has also coached 4 NAIA All-Americans, as well as 4 players that have played 
      professional baseball.`,
      `Austin has a strong passion for baseball and helping young athletes reach their full potential. As a relationship builder, he loves getting to know and 
      meet new players and their families in order to help them find their next college home. Austin and his wife Victoria currently reside in Davenport, FL.`,
    ],
    twitter: 'https://x.com/SwiftForReal',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      'naia-logo.png',
      '1645_indiana_wesleyan_university.png',
      '1708_ottawa_university.png',
    ],
    careerHighlights: [
      'Ottawa University - Recruiting Coordinator & Pitching Coach',
      'Ohio High School Player of the Year',
      'Helped set season records in wins at 2 different schools as both a player and a coach',
      'Played and coached in 3 regional tournaments',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D2':
        'Missouri Western State University, University of Texas Permian Basin, Chaminade University of Honolulu',
      'NCAA D3': 'Lyon College',
      NAIA: 'Ottawa University (KS), University of Saint Mary, Avila University, Benedictine College',
      NJCAA: 'Highland CC (KS), Labette CC',
    },
    calendly: 'https://calendly.com/coachaswift',
  },
  'ryan-sloniger': {
    photo: 'ryan_sloniger.jpg',
    fullName: 'Ryan Sloniger',
    title: 'College Baseball Advisor',
    bio: [
      `Ryan was a two-sport athlete at Punxsutawney area High School in Punxsutawney, PA. While at Punxsutawney he was a part of 4 District Championship's. 
      He received All-State honors three times, player of the year twice, and was a Team MVP three times. Ryan holds the school career records for Hits, Doubles, 
      & RBI's. After High School, Ryan went on to play at Penn State University where he had 166 career starts over 4 years as a catcher. He ranks fourth all time 
      in career putouts. After Ryan graduated from Penn State, he got drafted by the Toronto Blue Jays in the 38th round. He played in 3 levels of minor league 
      baseball over 3 years.
      
      Ryan began his coaching career immediately after being done playing professional baseball. He started as a volunteer assistant at Kent State working with 
      the Catchers and Pitchers. The next summer he became the Pitching Coach & Recruiting Coordinator at Gardner-Webb University, where he coached 3 all 
      conference pitchers including the conference pitcher of the year. He then went back to Kent State University to coach Pitchers/Catcher and assist with 
      recruiting responsibilities. 

      Ryan currently resides with his wife Alyssa in Bellefonte , PA.
    
      I have a passion for helping players understand the recruiting process and helping them find a home. I love being able to see a player finally understand 
      that all the work they are putting in his paying off for them.`,
    ],
    twitter: 'https://x.com/R_Sloni4',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: [
      'penn_state_logo.png',
      'blue_jays_logo.png',
      '794_kent_state_university.png',
      '756_gardner_webb_university.png',
    ],
    careerHighlights: [
      '7 Years Playing at the Division 1/Professional Level',
      '3 Years Division 1 Assistant Baseball Coach',
      'College Summer Baseball League Head Coach (2020)',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        'Kent State University, Liberty, Maryland Eastern Shore, Radford',
      'NCAA D3': "Penn State Harrisburg, Saint John's University",
    },
    calendly: 'https://calendly.com/coachsloniger4',
  },
  'jeremy-faoro': {
    photo: 'jeremy-faoro.jpg',
    fullName: 'Jeremy Faoro',
    title: 'College Baseball Advisor',
    bio: [
      `Jeremy was a two-sport athlete out of Mount Si High School in Snoqualmie Washington and played baseball at Pierce College. 
    Due to an injury, he was forced to quit playing and joined the coaching world.`,
      `In 2004 Jeremy started coaching football at Chief Kanim Middle School. That following spring Jeremy started coaching baseball at Mount Si High School. 
    He has been coaching baseball for 20 years now out of Mount Si. Over his time there he has several Kingco Championships as well as 1 State Championship 
    and two other state finalists. Jeremy also has 15 years coaching in the Summer Travel ball world. He is currently the Head Coach for the Washington A's 
    18u Premier team.`,
      `Jeremy has two children with his Wife Stefanie Faoro, and they reside in Issaquah WA.`,
    ],
    twitter: 'https://x.com/coachfaoro',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['Pierce_College_at_Puyallup.jpg'],
    careerHighlights: [
      '2011 WIAA 3A State Champion',
      '2012 WIAA 3A State Semi Finalist',
      '2013 WIAA 3A State Semi Finalist',
      '9 players drafted in the MLB Draft',
      '20 Years Coaching High School Baseball',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        "University of Washington, Washington State University, Gonzaga University, Oregon State University, University of Oregon, Seattle University, Yale University, Texas Tech University, Kansas State University, New Mexico State University, University of Illinois, University of San Fransisco, Santa Clara University, University of Arizona, University of Connecticut, University of Hawai'i, University of Louville, Seattle Pacific University, Boise State University, University of Northern Colorado",
      'NCAA D2':
        "Azuza Pacific University, Central Washington University, University of Charleston, Colorado School of Mines, Hawai'i Pacific University",
      'NCAA D3':
        'Whitworth University, Whitman College, University of Puget Sound, Pacific Lutheran University, University of Redlands, Colby College, George Fox University, Lewis and Clark College, Linfield College, Pacific University, Willamette University',
      NAIA: '',
      NJCAA:
        'Bellevue College, Pierce CC, Tacoma CC, Linn-Benton CC, Lower Columbia CC, Everett CC, Shoreline CC, Spokane CC, Columbia Basin CC, Wenatchee Valley CC, Walla Walla CC, Yakima CC, Centralia CC, Central Arizona CC, Chattanooga State CC',
    },
    calendly: 'https://calendly.com/coachjfaoro',
  },
  'michael-mcnamara': {
    photo: 'michael-mcnamara.jpg',
    fullName: 'Michael McNamara',
    title: 'College Baseball Advisor',
    bio: [
      `I have a strong passion for helping young student athletes and their families find a home for the next 4 years of their lives. The recruiting process can 
      be very confusing and complex, and I am here to simplify and assist along the way.`,
      `I played varsity football and baseball at Saint Ignatius High School in 
      Cleveland Ohio and won the OHSAA baseball state championship in 2019. After graduating in 2020, I decided to play baseball over football at Kent State 
      University. I studied Sports Administration and Business throughout my 4 years there. In 2023, we won the MAC regular season and had 42 wins throughout 
      the season, resulting in one of the best seasons in Kent State's history.`,
    ],
    twitter: 'https://x.com/mmac828',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['794_kent_state_university.png'],
    careerHighlights: [
      '2nd team All-MAC in 2021 and 2022',
      'All-MAC defensive team in 2021 at Kent State University',
      '6th all-time in home runs at Kent State University with 39.',
    ],
    myPlayerCollegesByDivision: {},
    calendly: 'https://calendly.com/coachmcnamara8',
  },
  'mike-burtner': {
    photo: 'mike-burtner.jpeg',
    fullName: 'Mike Burtner',
    title: 'College Baseball Advisor',
    bio: [
      `Mike was a three-sport athlete at Bridgewater College where he was a member of the 1994 ODAC Championship and NCAA Regional team in baseball while 
      earning a BS degree in Computer Science.  He later earned a Master of Engineering degree in Systems Engineering from Old Dominion University.`,
      `In 2001, Mike began coaching the Elkton Blue Sox in the Rockingham County Baseball League and was named the RCBL Manager of the Year three times in a 
      seven-year span while winning 2 regular season and 1 playoff championships. Mike also coached 8 MLB Draft picks and numerous other Division 1 players. 
      In 2024, Mike was inducted into the Rockingham County Baseball League's Hall of Fame.`,
      `After coaching in the RCBL, Mike turned his focus to coaching youth and travel baseball and has amassed 25 years of coaching experience.  In just the 
      last 4 years, over 60 players from Mike's travel teams have gone on to play college baseball at just about every level.`,
      `Mike currently resides near Harrisonburg, VA.`,
    ],
    twitter: 'https://x.com/coachburtner',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['54_bridgewater_college_va.png'],
    careerHighlights: [
      'Inducted into the Rockingham County Baseball League Hall of Fame (2024)',
      'Co-Founder of Valley Elite travel baseball program',
      'Member of 1994 ODAC Championship Team at Bridgewater College',
      '25 years of coaching experience',
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1':
        'University of Virginia, James Madison University, Longwood University, Virginia Military Institute, Charleston Southern, Samford University, University of Maryland-Baltimore County, Liberty University, Old Dominion University, Brown University, Dartmouth University, William & Mary',
      'NCAA D2':
        'Tampa University, Fairmont State University, Concord University, University of Charleston, Salem University, Shepherd University, Emory & Henry College',
      'NCAA D3':
        'Bridgewater College, Eastern Mennonite University, Mary Baldwin University, Ferrum College, Shenandoah University, Christopher Newport University, McDaniel College, Southern Virginia University, University of Lynchburg, Randolph Macon College',
      NAIA: 'Bluefield University, West Virginia Tech',
      NJCAA:
        'Potomac State, Lehigh Carbon Community College, Frederick Community College, Richard Bland College, Virginia Peninsula Community College, Bryant & Stratton College, Lenior Community College, Wake Tech Community College, Patrick & Henry Community College, New River Community College',
    },
    calendly: 'https://calendly.com/coachburtner',
  },
  'petey-torres': {
    photo: 'petey_torres.jpg',
    fullName: 'Petey Torres III',
    title: 'College Baseball Advisor',
    bio: [
      `Born and raised in the Bay Area, California, Petey Torres III has dedicated his life to the game of baseball, excelling as both a player and a coach. 
      Petey’s journey began as a four-year high school varsity starter, earning All-MEL Team honors from his sophomore to senior year.`,
      `He continued his baseball career at Santa Rosa Junior College, where he played for a nationally ranked team for three consecutive years. Petey then advanced to Clarke University 
      from 2020 to 2022, where he earned recognition as a Gold Glove winner at catcher in 2021 and was named to the Heart of America Conference 2nd Team the same year. His journey in collegiate 
      baseball continued as he played Division II baseball at the Academy of Arts in 2023.`,
      `Beyond his playing career, Petey has shifted his focus to helping develop the next generation of baseball talent. He is currently collaborating with over 30+  families, providing guidance 
      and training to young athletes under his own training business "PT3 Fundamentals"  Petey also played a key role in the founding and development of the Cepeda Sports travel ball organization, 
      where he shares his expertise and passion for the sport.`,
      `Petey’s commitment to fostering both physical skills and mental resilience in athletes reflects his dedication to building well-rounded players who excel on and off the field.`,
    ],
    twitter: 'https://x.com/Coachpete00',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['Santa_Rosa_Junior_College.png', '1791_Clarke_University.png'],
    careerHighlights: [
      `Four-Year High School Varsity Starter: Earned All-MEL Team honors from sophomore to senior year`,
      `Santa Rosa Junior College: Played for a nationally ranked team for three consecutive years`,
      `Clarke University Alumni: Gold Glove winner at catcher in 2021 and Heart of America Conference 2nd Team selection`,
      `Division II Player: Competed at the Academy of Arts in 2023`,
      `Coaching and Development: Collaborating with over 25 families to train and guide young athletes`,
      `Cepeda Sports: Co-founder of a travel ball organization focused on player development`,
    ],
    myPlayerCollegesByDivision: {
      'NCAA D2': 'Academy of Art Institute',
      NAIA: 'Clarke University',
      NJCAA: 'Santa Rosa Junior College',
    },
    calendly: 'https://calendly.com/coachpete00',
  },
  'will-tucker': {
    photo: 'will_tucker.jpeg',
    fullName: 'Will Tucker',
    title: 'College Baseball Advisor',
    bio: [
      `Student Assistant and Director of Baseball Operations at University of Tampa from 2017-2020. NCAA D2 National Championship 2019. Player Development 
      Assistant for New York Yankees in 2018. Director of Baseball Operations at Combine Academy from 2020-2021. Recruiting Coordinator and Hitting Coach for 
      NJCAA Monroe University Bronx Campus. Took program from 9 to 31 wins in two years including schools first ever region championship. 2023-Present, 
      Hitting Coach and Recruiting Assistant at NJCAA Hillsborough Community College.`,
      `B.A in Sports Management from University of Tampa`,
      `M.Ed in Clinical Mental Health Counseling from St. Bonaventure University`,
      `Extremely passionate about helping young men develop into the best version of themselves while creating roadmaps to accomplish their respective goals.`
    ],
    twitter: 'https://x.com/will_tucker13',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['542_university_of_tampa.png','Hillsborough_Community_College.jpg'],
    careerHighlights: [
      `2019 NCAA D2 National Championship`,
      `2021 NJCAA Region 15 Championship`,
      `Sent over 100 players to play at next level.`
    ],
    myPlayerCollegesByDivision: {
      'NCAA D1': `Queens College, University of South Florida, Notre Dame University, Georgia Tech University, University of North Carolina Wilmington, 
      Wake Forest University,University of Tennessee, University of Alabama Birmingham, University of Louisiana Monroe, University of South Carolina, Mississippi 
      Valley State University, Georgia Southern College, Clemson University, University of Charlotte, Old Dominion University, Elon University, West Georgia 
      University, Bethune-Cookman University`,
      'NCAA D2': `University of Tampa, Florida Southern College, Belmont Abbey College, Bluefield State College, North Greenville University, Eastern New Mexico 
      University, American International College, Mercy University, Barry University, Eckerd College, Lynn University`,
      'NCAA D3': `Bridgewater State University, Lesley University, Marian University, City College of New York, Suny Cobelskill, University of Texas Dallas`,
      NAIA: `Graceland University, University of Health & Pharmacy Sciences, Oklahoma Panhandle State University, New College of Florida, Sterling College, Florida 
      Memorial University, Waldroff University`,
      NJCAA: `Wabash Valley Community College, Caldwell Tech, San Jacinto Community College, Kaskaskia Community College, Kankakee Community College, College of Central Florida, Andrew College, 
      Monroe University New Rochelle Campus, Gordon State College, Tyler Junior College, Danville Area Community College, Western Nebraska College`,
    },
    calendly: 'https://calendly.com/coachtucker313',
  },
  'gavin-napier': {
    photo: 'gavin_napier.jpg',
    fullName: 'Gavin Napier',
    title: 'College Baseball Advisor',
    bio: [
      `I attended Granada High School where I was a 3-year starter on varsity where I received multiple All-EBAL honors my sophomore and junior year. 
      After graduating from Granada, I decided to attend Saint Mary's College of California. During my time at SMC, I was able to play and start for all 
      4 years while earring a Bachelor's Degree in Sports Management.`, 
      `After my playing days were over, I knew I wanted to still be involved in baseball and started coaching at Los Medanos College. I have always had a 
      passion for baseball and helping other people, and I felt like becoming an advisor is a great way to help players in a similar situation I was in and 
      to be a resource to help them achieve their dreams.`
    ],
    twitter: 'https://x.com/Gavin_Napier_2',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['ncaa-logo.png'],
    careerHighlights: [
      'All-EBAL Second Team, Iron Gael Award Recipient',
      '3x Student-Athlete of the Week',
      '2022 WCC All-Academic Team'
    ],
    myPlayerCollegesByDivision: {},
    calendly: 'https://calendly.com/coachnapier2',
  },
  'chris-vaculik': {
    photo: 'chris_vaculik.jpg',
    fullName: 'Chris Vaculik',
    title: 'College Baseball Advisor',
    bio: [
      `From 2004-2007, I was a Division 1 Athlete at Eastern Illinois University.  During my playing career under, Jim Schmitz, I was able to finish as a pitcher 
      for the Panthers I ended my tenure as the Career Saves Leader (18), leader in appearances (102), 3rd in Wins (20), 4th in Innings Pitched (261.3) & 5th in 
      Strikeouts (210) for the University.  I was voted by the team and coaches on three separate occasions as the Pitcher of the Year.`,
      `After my playing career I began my coaching career with the Hounds Baseball Organization based out of Willowbrook, Illinois in 2017.  While there I was tasked 
      with coaching High School Level Travel Teams.  During that time, I grew my Coaching Network within the Twitter/X Space. With success of placing players within 
      my teams, growing my network, and ability to help other teams within our organization, I was promoted to the recruiting coordinator for the Hounds.`,
      `For the past 3 years, I transitioned into coaching for one of the biggest travel organizations in the state of Illinois (Cangelosi Sparks - Lockport).  While 
      there, I have continued coaching not only High School Level Elite Teams but been a key instructor for our youth programing.`
    ],
    twitter: 'https://x.com/ChrisVaculik',
    facebook: '',
    instagram: '',
    linkedin: '',
    logoList: ['726_eastern_illinois_university.png'],
    careerHighlights: [
      "Coached for 10 years for some of Illinois' Top High School Travel Teams",
      "Have been promoted as a Recruiting Coordinator, and rolled out programs to help athletes get noticed and recruited.",
      'Strong background with the process, details, and "know how" on how to get recruited'
    ],
    myPlayerCollegesByDivision: {},
    calendly: 'https://calendly.com/coachchrisvaculik',
  },
  //   photo: '',
  //   fullName: '',
  //   title: '',
  //   bio: [],
  //   twitter: '',
  //   facebook: '',
  //   instagram: '',
  //   linkedin: '',
  //   logoList: [],
  //   careerHighlights: [],
  //   myPlayerCollegesByDivision: {
  //     'NCAA D1': '',
  //     'NCAA D2': '',
  //     'NCAA D3': '',
  //     NAIA: '',
  //     NJCAA: '',
  //   },
  //   calendly: '',
  // },
};
